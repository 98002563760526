.row-header {
  margin: 0px auto;
  padding: 0px auto;
}

ul {
  list-style-type: none;
}

.row-content {
  margin: 0px auto;
  padding: 50px 0px 50px 0px;
  border-bottom: 1px ridge;
  min-height: 400px;
}
.footer {
  background-color: hsl(172.29deg 62.52% 51.33%);
  margin: 0px auto;
  padding: 20px 0px 20px 0px;
  color: white;
}
.jumbotron {
  padding: 10%;
  margin: 0px auto;
  background-image: url(../public/Background0.png);
  background-repeat: no-repeat;
  background-size: cover;
  color: rgb(255, 255, 255);
  font-size: xx-large;
  font-weight: 600;
  min-height: 100vh;
  text-shadow: 0 0 5px #030101, 0 0 5px #010801;
}
.jumbotron h1 {
  padding-top: 3%;
  font-size: 1.4577em;
  font-style: bold;
  color: rgb(43, 229, 43);
  font-family: "Bangers", sans-serif;
}

.jumbotron p {
  color: #a9e420c4;
  font-size: 25px;
  padding-bottom: 3%;
  animation: flyin infinite;
}
address {
  font-size: 80%;
  margin: 0px;
  color: #0f0f0f;
}

.navbar-dark {
  color: #05386b;
  background-color: rgb(52, 61, 59);
  background-image: url("/public/Background0.png");
}
.navbar-dark {
  background-blend-mode: soft-light;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgb(255, 255, 255);
  transition-duration: 500ms;
}
.navbar-dark .navbar-nav .nav-link.active {
  color: #33ff00;
  font-size: 18px;
}
.navbar-dark .navbar-nav .nav-link:hover {
  color: rgb(60, 255, 248);
  font-size: 18px;
}
.page-enter {
  opacity: 0.01;
  transform: translateX(-100%);
}

.page-enter-active {
  opacity: 1;
  transform: translateX(0%);
  transition: all 300ms ease-in;
}

.page-exit {
  opacity: 1;
  transform: translateX(0%);
}

.page-exit-active {
  opacity: 0.01;
  transform: translateX(100%);
  transition: all 300ms ease-out;
}

.btn-outline-secondary {
  background-image: linear-gradient(
    to right,
    rgb(231, 4, 231),
    rgb(71, 19, 214)
  );
  color: white;
}
.btn-outline-secondary:hover {
  color: #ffc400;
}
.shadow {
  box-shadow: #05386b, 4, 4, 0.5;
}
